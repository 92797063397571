import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Slider from 'react-slick';
import Link from 'components/Link';
import PaginationArrow from 'components/MediaRelationsSlider/PaginationArrow';
import Utils from '../../utils';

const sliderSettings = {
  arrows: true,
  autoplaySpeed: 9000,
  centerMode: false,
  dots: false,
  infinite: true,
  nextArrow: (<PaginationArrow isNext />),
  prevArrow: (<PaginationArrow />),
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  variableWidth: false,
  responsive: [
    {
      breakpoint: 1240,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 960,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const SuccessSlider = () => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(
          filter: {
            frontmatter: {
              example: { ne: true }
            }
            fileAbsolutePath: {regex: "/(../successes)/.*\\.md$/"}
          }
          sort: {order: ASC, fields: [frontmatter___order]}
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                client
                logo {
                  childImageSharp {
                    resize(height: 40, grayscale: true) {
                      src
                    }
                  }
                  relativePath
                }
                cardheading
                cardsubheading
                title
              }
            }
          }
        }
      }
    `}
    render={({ allMarkdownRemark }) => (
      <Slider className="carousel mv4" {...sliderSettings}>
        {allMarkdownRemark.edges.map(({ node }) => {
          const logoPath = Utils.getImagePath(node.frontmatter.logo);

          return (
            <Link
              key={node.id}
              to={`/successes${node.fields.slug}`}
              className="link"
            >
              <div className="carousel-cell link mv4 mh2 pa3">
                <img
                  className="success--logo relative"
                  src={logoPath}
                  width="auto"
                  height="40px"
                  alt={node.frontmatter.client}
                />

                <div className="flex flex-column content-center">
                  <h6 className="ma0">{node.frontmatter.cardheading}</h6>
                  <h2 className="ma0">{node.frontmatter.cardsubheading}</h2>
                </div>
              </div>
            </Link>
          );
        })}
      </Slider>
    )}
  />
);

export default SuccessSlider;
