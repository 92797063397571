import React from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';
import Link from 'components/Link';

const TeamList = ({ summary, title }) => (
  <StaticQuery
    query={graphql`
      query {
        team: allMarkdownRemark(
          filter: {
            frontmatter: {
              example: { ne: true }
            }
            fileAbsolutePath: {regex: "/(../team)/.*\\.md$/"}
          }
          sort: {order: ASC, fields: [frontmatter___order]}
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                position
                homeposition
              }
            }
          }
        }
      }
    `}
    render={({ team }) => (
      <section className="people container w-100 pv4 db-ns">
        <div className="heading w-100 pv4 pt4-ns center">
          <h1>{title}</h1>
          <p>{summary}</p>
        </div>

        <div className="bios flex flex-wrap w-100 pv4">
          {team.edges.map(({ node }) => (
            <div key={node.id} className="bio w-100 w-50-m w-third-ns pr4-ns">
              <Link to={`/team${node.fields.slug}`}>
                <h2>
                  {node.frontmatter.homeposition || node.frontmatter.position}
                </h2>

                <p className="name di link f5">
                  {node.frontmatter.title}
                </p>
              </Link>
            </div>
          ))}
        </div>
      </section>
    )}
  />
);

TeamList.propTypes = {
  title: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
};

export default TeamList;
