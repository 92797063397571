import React from 'react';
import PropTypes from 'prop-types';
import Arrow from 'components/Arrow';
import Utils from '../../utils';

const Testimonial = ({
  children,
  name,
  title,
  client,
  clientLogo,
  provider,
  providerLogo,
}) => {
  const companyImages = () => {
    if (!client || !clientLogo || !Utils.hasNestedProp(clientLogo, ['childImageSharp', 'resize', 'src'])) {
      return null;
    }

    if (!provider || !providerLogo || !Utils.hasNestedProp(providerLogo, ['childImageSharp', 'resize', 'src'])) {
      return (
        <div className="morphing flex justify-center items-center mb3">
          <img src={clientLogo.childImageSharp.resize.src} height="40px" alt={client} />
        </div>
      );
    }

    return (
      <div className="morphing flex justify-center items-center mb3">
        <img src={clientLogo.childImageSharp.resize.src} height="40px" alt={client} />

        <Arrow className="mh2" color="#CFBDA1" />

        <img src={providerLogo.childImageSharp.resize.src} height="40px" alt={provider} />
      </div>
    );
  };

  return (
    <div className="quote carousel-cell ph3">
      {companyImages()}

      <p className="f5 f4-ns lh-copy tc">
        <em>{children}</em>
      </p>

      <h6 className="pt2 tc">
        {name} | <b>{title}, {client}</b>
      </h6>
    </div>
  );
};

Testimonial.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]).isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  client: PropTypes.string.isRequired,
  clientLogo: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      resize: PropTypes.shape({
        src: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  provider: PropTypes.string,
  providerLogo: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      resize: PropTypes.shape({
        src: PropTypes.string,
      }),
    }),
  }),
};

Testimonial.defaultProps = {
  provider: null,
  providerLogo: null,
};

export default Testimonial;
