import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { graphql, StaticQuery } from 'gatsby';
import Testimonial from 'components/Testimonials/Testimonial';

const sliderSettings = {
  autoplay: true,
  autoplaySpeed: 8000,
  dots: true,
  fade: true,
};

const TestimonialList = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(
          filter: {
            frontmatter: {
              active: { eq: true }
              example: { ne: true }
            }
            fileAbsolutePath: {regex: "/(../testimonials)/.*\\.md$/"}
          }
          sort: {order: ASC, fields: [frontmatter___order]}
        ) {
          edges {
            node {
              id
              rawMarkdownBody
              frontmatter {
                title
                position
                client
                clientlogo {
                  childImageSharp {
                    resize(height: 40, grayscale: true) {
                      src
                    }
                  }
                  relativePath
                }
                provider
                providerlogo {
                  childImageSharp {
                    resize(height: 40, grayscale: true) {
                      src
                    }
                  }
                  relativePath
                }
              }
            }
          }
        }
      }
    `}
    render={({ allMarkdownRemark }) => (
      <section className={`testimonials center ${className}`}>
        <Slider className="main-carousel pl0" {...sliderSettings}>
          {allMarkdownRemark.edges.map(({ node }) => (
            <Testimonial
              key={node.id}
              name={node.frontmatter.title}
              title={node.frontmatter.position}
              client={node.frontmatter.client}
              clientLogo={node.frontmatter.clientlogo}
              provider={node.frontmatter.provider}
              providerLogo={node.frontmatter.providerlogo}
            >
              {node.rawMarkdownBody}
            </Testimonial>
          ))}
        </Slider>
      </section>
    )}
  />
);

TestimonialList.propTypes = {
  className: PropTypes.string,
};

TestimonialList.defaultProps = {
  className: '',
};

export default TestimonialList;
