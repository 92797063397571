import React from 'react';
import Rellax from 'react-rellax';
import Helmet from 'react-helmet';
import PageTemplate from 'templates/PageTemplate';
import TeamList from 'components/TeamList';
import Successes from 'components/Successes';
import TestimonialList from 'components/Testimonials/TestimonialList';
import ContactBox from 'components/ContactBox';
import Services from 'components/Services';

import Hero from 'images/hero.png';
import Sand from 'images/sand.png';

const IndexPage = () => (
  <PageTemplate>
    <Helmet title="Home" />

    <section className="hero w-100 center">
      <div className="stretch container fl-ns relative z-4 pa4 pb0 mb0">
        <p className="impact">Take <em>control of your market</em>–OutVox the competition.</p>
        <p className="reflect absolute top-2 o-20">Take <em>control of your market</em>–OutVox the competition.</p>
      </div>

      <Rellax speed={2} zIndex={1} className="hero--image">
        <img
          className="shadow cb relative mb4 mb0-l"
          src={Hero}
          alt="Modern Architecture"
        />
      </Rellax>

      <div className="sub--statement stretch cb pa4 pt0-l relative">
        <h3 className="pb4">They did...</h3>
      </div>
    </section>

    <TestimonialList className="container cb" />

    <section className="anchor" id="services">
      <Services />
    </section>

    <section className="relative anchor" id="successes">
      <Rellax className="absolute top-0 right-0 z-1" percentage={-4.4} speed={1}>
        <img
          className="sand shadow cb-m fr-ns z-9 mr5-ns relative"
          src={Sand}
          alt="Sand"
        />
      </Rellax>

      <Successes
        title="Successes"
        successesSummary="CEOs and investors hire OutVox based on our track record."
        outcommunicateSummary="Strategic media featuring the latest news in business, tech, and finance."
      />
    </section>

    <section className="anchor cf" id="about">
      <div className="about pv5 relative z-1">
        <div className="container relative top-1-ns center">
          <h1 className="mb5">About Us</h1>
          <h2 className="w-70-ns f3 f3-m f2-ns white">OutVox is a boutique management consultancy. CEOs and investors rely on our strategic guidance and operational support to differentiate, drive revenue, and scale their businesses.</h2>
        </div>
      </div>

      {/* Keeping this here for parallax fix later */}
      {/* eslint-disable max-len */}
      {/* <Rellax
            speed={1}
            className="floating-about shadow w-80 w-50-m w-third-l w-25-ns fr-ns cr mb6 mb0-l mr5-ns center relative z-5"
          >
        <p className="pv3 ph4">
          We are former CXOs&mdash;CEOs, GMs, CMOs, sales and business development
          execs&mdash;who have sat in your seat before. We know what it takes to run
          and grow a business. We’ve built, bought and sold companies in a variety
          of industries.
        </p>
      </Rellax> */}
      {/* eslint-enable max-len */}

      <div className="floating-about shadow w-80 w-50-m w-third-l w-25-ns fr-ns cr mb6 mb0-l mr5-ns center relative z-5">
        <p className="pv3 ph4">We are former CXOs&mdash;CEOs, GMs, CMOs, sales and business development execs&mdash;who have sat in your seat before. We know what it takes to run and grow a business. We’ve built, bought and sold companies in a variety of industries. </p>
      </div>
    </section>

    <TeamList
      title="Our Leadership"
      summary="We are equally successful in the boardroom and in the trenches."
    />

    <section className="anchor" id="contact">
      <ContactBox />
    </section>
  </PageTemplate>
);

export default IndexPage;
