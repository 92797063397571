import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

Modal.setAppElement('#___gatsby');

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const ModalVideoPlayer = ({
  isOpen,
  onClose,
  timestamp,
  videoId,
}) => {
  const convertTimestampToSeconds = () => {
    let seconds = 0;

    if (!timestamp || timestamp.split(':').length <= 0) {
      return '';
    }

    const tsArray = timestamp.split(':');

    if (tsArray.length === 3) {
      seconds += Number(tsArray[0]) * 60 * 60;
      seconds += Number(tsArray[1]) * 60;
      seconds += Number(tsArray[2]);
      return `&start=${seconds}`;
    }

    if (tsArray.length === 2) {
      seconds += Number(tsArray[0]) * 60;
      seconds += Number(tsArray[1]);
      return `&start=${seconds}`;
    }

    if (Number(tsArray[0]) > 0) {
      return `&start=${Number(tsArray[0])}`;
    }

    return '';
  };
  const youtubeUrl = `https://www.youtube.com/embed/${videoId}?autoplay=1&origin=https://www.outvox.com${convertTimestampToSeconds()}`;

  return (
    <Modal
      className="modal"
      closeTimeoutMS={300}
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Example Modal"
      overlayClassName="modal--overlay"
      style={customStyles}
    >
      <button
        aria-label="Close"
        className="close"
        onClick={onClose}
        type="button"
      />

      <div className="video-responsive">
        <iframe
          id="ytplayer"
          title="Video Player"
          type="text/html"
          width="640"
          height="360"
          src={youtubeUrl}
          frameBorder="0"
          allowFullScreen
        />
      </div>
    </Modal>
  );
};

ModalVideoPlayer.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  timestamp: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  videoId: PropTypes.string,
};

ModalVideoPlayer.defaultProps = {
  isOpen: false,
  timestamp: null,
  videoId: null,
};

export default ModalVideoPlayer;
