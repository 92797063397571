import React from 'react';
import PropTypes from 'prop-types';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import BackToTop from 'components/BackToTop';
import CrackedEarth from 'images/cracked-earth.jpg';
import LinkedIn from 'images/linkedin-in.png';
import Envelope from 'images/envelope-icon.png';
import PointPin from 'images/pointpin.png';

const ContactBox = ({ className }) => (
  <div
    className="contact"
    style={{ backgroundImage: `url(${CrackedEarth})` }}
  >
    <div className={`contact--container ${className}`}>
      <h1 className="contact--title">
        Contact Us
      </h1>

      <ul className="contact--list">
        <li className="contact--item">
          <img src={LinkedIn} alt="LinkedIn icon" />

          <OutboundLink href="https://www.linkedin.com/company/outvox/">
            <h3>
              Connect on LinkedIn
            </h3>
          </OutboundLink>
        </li>

        <li className="contact--item">
          <img src={Envelope} alt="Envelope icon" />

          <a href="mailto:consultation@outvox.com">
            <h3>
              consultation@outvox.com
            </h3>
          </a>
        </li>

        <li className="contact--item">
          <img src={PointPin} alt="Map pin icon" />

          <address>
            <h3>
              <a href="https://www.google.com/maps/place/1300+19th+St+NW,+Washington,+DC+20036/@38.9074548,-77.0461768,17z/data=!3m1!4b1!4m5!3m4!1s0x89b7b7c7f6b725ed:0x1aa41b8812f86968!8m2!3d38.9074548!4d-77.0439881" target="_blank" rel="noopener noreferrer" title="Link to Washington, DC location on Google Maps">
                1300 19th Street NW<br />
                Washington, DC 20036
              </a>
            </h3>
          </address>

          <h3>(202) 379 3085</h3>
        </li>
      </ul>
    </div>

    <BackToTop className="mt4" />
  </div>
);

ContactBox.propTypes = {
  className: PropTypes.string,
};

ContactBox.defaultProps = {
  className: '',
};

export default ContactBox;
