import React from 'react';
import PropTypes from 'prop-types';
import Arrow from 'components/Arrow';

const PaginationArrow = (props) => {
  const {
    currentSlide,
    isNext,
    slideCount,
    ...remainingProps
  } = props;

  if (isNext) {
    return (
      <button aria-label="Next" type="button" className="slick-next" {...remainingProps}>
        <Arrow />
      </button>
    );
  }

  return (
    <button aria-label="Previous" type="button" className="slick-prev" {...remainingProps}>
      <Arrow left />
    </button>
  );
};

PaginationArrow.propTypes = {
  currentSlide: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  isNext: PropTypes.bool,
  slideCount: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

PaginationArrow.defaultProps = {
  currentSlide: null,
  isNext: false,
  slideCount: null,
};

export default PaginationArrow;
