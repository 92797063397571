import React from 'react';
import PropTypes from 'prop-types';
import { animateScroll as scroll } from 'react-scroll';

const BackToTop = ({ className }) => (
  <button className={`top__link tc db center pa2 link ${className}`} onClick={scroll.scrollToTop} type="button">
    <svg className="relative top-1 center" width="12px" height="18px" viewBox="0 0 12 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Up Arrow</title>
      <desc>Arrow pointing to top of page</desc>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
        <g transform="translate(-634.000000, -5817.000000)" stroke="#9C773D">
          <g transform="translate(634.000000, 5818.000000)">
            <path d="M5.5,16.481681 L5.5,0.169800491" transform="translate(5.500000, 8.327586) scale(1, -1) translate(-5.500000, -8.327586)" />
            <polyline strokeLinejoin="round" transform="translate(5.635000, 2.599310) scale(1, -1) translate(-5.635000, -2.599310) " points="0 8.8817842e-16 5.70699495 5.19862049 11.2700005 0.131162973" />
          </g>
        </g>
      </g>
    </svg>

    <p className="w-100 tc mt3 ttu">
      Back to Top
    </p>
  </button>
);

BackToTop.propTypes = {
  className: PropTypes.string,
};

BackToTop.defaultProps = {
  className: '',
};

export default BackToTop;
