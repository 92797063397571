import React from 'react';
import Rellax from 'react-rellax';
import Link from 'components/Link';
import Arrow from 'components/Arrow';
import Loop from 'images/architecture-loop.jpg';

const Services = () => (
  <div className="service--list">
    <Rellax
      className="relative z1"
      speed={2}
      percentage={-2}
      z-index={1}
    >
      <img
        className="architecture-loop--image shadow cb ml4 absolute"
        src={Loop}
        width="270px"
        height="auto"
        alt="Architectural loop"
      />
    </Rellax>

    <div className="relative z2">
      <h1 className="mv4 tc">
        Out<span className="b">Vox</span> The Competition
      </h1>

      <div className="services--main flex-l flex-start-l w-90-ns pv4 center">
        <div className="w-100 w-100-m w-third-l w-third-ns ph2 center">
          <h4 className="tc white">
            OUT<b><i>POSITION</i></b>
          </h4>

          <p className="tc i">
            Go-to-market strategy
          </p>

          <ul className="list pl0 pt2 tc">
            <li className="list-item">
              Surface what matters most to buyers.
            </li>

            <li className="list-item">
              Craft the hard-hitting message.
            </li>

            <li className="list-item">
              Put your competitors in a box.
            </li>
          </ul>

          <div className="tc pt2">
            <Link to="/go-to-market-strategy" className="no-underline">
              LEARN MORE
              <Arrow className="mh2" />
            </Link>
          </div>
        </div>

        <div className="middle-col w-100 w-100-m w-third-l w-third-ns mw5 mw-100-ns bn-ns mv4 mv4-m mv0-ns ph2 center">
          <h4 className="tc white">
            OUT<b><i>COMMUNICATE</i></b>
          </h4>

          <p className="tc i">
            Strategic media relations
          </p>

          <ul className="list pl0 pt2 tc">
            <li className="list-item">
              Execute strategically.
            </li>

            <li className="list-item">
              Go for quality over quantity.
            </li>

            <li className="list-item">
              Be relevant on the global stage.
            </li>
          </ul>

          <div className="tc pt2">
            <Link to="/strategic-communications" className="no-underline">
              LEARN MORE
              <Arrow className="mh2" />
            </Link>
          </div>
        </div>

        <div className="w-100 w-100-m w-third-l w-third-ns ph2 center">
          <h4 className="tc white">
            OUT<b><i>PERFORM</i></b>
          </h4>

          <p className="tc i">
            Corporate development and operational services
          </p>

          <ul className="list pl0 pt2 tc">
            <li className="list-item">
              Align to the opportunity.
            </li>

            <li className="list-item">
              Remove the barriers.
            </li>

            <li className="list-item">
              Deliver 10x returns.
            </li>
          </ul>

          <div className="tc pt2">
            <Link to="/corporate-development" className="relative z-5 no-underline">
              LEARN MORE
              <Arrow className="mh2" />
            </Link>
          </div>
        </div>
      </div>
    </div>

    <div className="outexperience--bucket w-100 mt4 pv5 pv3-l center">
      <h4 className="tc white">
        OUT<b><i>EXPERIENCE</i></b>
      </h4>

      <p className="tc i">
        The OutVox advisory network
      </p>

      <div className="network--bullets flex flex-column flex-row-l justify-center w-80-l center tc">
        <ul className="w-100 center flex items-center justify-between list">
          <li className="list-item">
            Expand your mind.
          </li>

          <li className="list-item">
            Expand your impact.
          </li>
        </ul>
      </div>
    </div>
  </div>
);

export default Services;
