import React from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';
import TickerCardList from '../TickerCards/TickerCardList';

const SuccessOutCommunicate = ({ onCardClick }) => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(
          filter: {
            fileAbsolutePath: {regex: "/(../media-samples)/.*\\.md$/"}
            frontmatter: {
              example: { ne: true }
              featureHome: { eq: true }
            }
          }
          sort: {order: ASC, fields: [frontmatter___order]}
        ) {
          edges {
            node {
              id
              frontmatter {
                type
                background {
                  childImageSharp {
                    resize(width: 700, quality: 100) {
                      src
                    }
                  }
                  relativePath
                }
                heading
                logo {
                  childImageSharp {
                    resize(height: 100) {
                      src
                    }
                  }
                  relativePath
                }
                logoAlt
                subheading
                timestamp
                title
                videoid
              }
            }
          }
        }
      }
    `}
    render={({ allMarkdownRemark }) => (
      <TickerCardList
        cards={allMarkdownRemark.edges}
        onCardClick={onCardClick}
        allowFourColumnLayout
      />
    )}
  />
);

SuccessOutCommunicate.propTypes = {
  onCardClick: PropTypes.func.isRequired,
};

export default SuccessOutCommunicate;
