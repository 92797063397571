import React from 'react';
import PropTypes from 'prop-types';
import TickerCard from './TickerCard';
import Utils from '../../utils';

class TickerCardList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cardsPerPage: 6,
      loadMoreIsVisible: false,
      visible: 0,
    };

    this.handleLoadMoreClick = this.handleLoadMoreClick.bind(this);
  }

  componentDidMount() {
    const { cards } = this.props;
    const { cardsPerPage } = this.state;

    if (cards.length > cardsPerPage) {
      this.setState({
        loadMoreIsVisible: true,
        visible: cardsPerPage,
      });
    } else {
      this.setState({
        loadMoreIsVisible: false,
        visible: cards.length,
      });
    }
  }

  getLoadMoreButton() {
    const { loadMoreIsVisible } = this.state;

    if (loadMoreIsVisible) {
      return (
        <button
          className="btn"
          onClick={this.handleLoadMoreClick}
          type="button"
        >
          Load More
        </button>
      );
    }

    return null;
  }

  handleLoadMoreClick() {
    const { cards } = this.props;
    const { cardsPerPage, visible } = this.state;

    if (visible + cardsPerPage >= cards.length) {
      this.setState({
        loadMoreIsVisible: false,
        visible: visible + cardsPerPage,
      });

      return;
    }

    this.setState({
      visible: visible + cardsPerPage,
    });
  }

  render() {
    const { cards, onCardClick, allowFourColumnLayout } = this.props;
    const { visible } = this.state;
    const tickerListColumns = (allowFourColumnLayout === true ? 'ticker--list-max-four-cols' : '');

    return (
      <div className="ticker">
        <div className={`ticker--list ${tickerListColumns}`}>
          {cards.slice(0, visible).map(({ node }) => {
            const logoPath = Utils.getImagePath(node.frontmatter.logo);
            const bgPath = Utils.getImagePath(node.frontmatter.background);

            return (
              <TickerCard
                key={node.id}
                background={bgPath}
                handleClick={onCardClick}
                logo={logoPath}
                logoAlt={node.frontmatter.logoAlt}
                subtitle={node.frontmatter.subheading}
                timestamp={node.frontmatter.timestamp}
                title={node.frontmatter.heading}
                type={node.frontmatter.type}
                url={node.frontmatter.title}
                videoid={node.frontmatter.videoid}
              />
            );
          })}
        </div>

        {this.getLoadMoreButton()}
      </div>
    );
  }
}

TickerCardList.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.shape({
    node: PropTypes.object.isRequired,
  })).isRequired,
  onCardClick: PropTypes.func.isRequired,
  allowFourColumnLayout: PropTypes.bool,
};

TickerCardList.defaultProps = {
  allowFourColumnLayout: false,
};

export default TickerCardList;
