import React from 'react';
import PropTypes from 'prop-types';
import DefaultImage from 'images/naphtali-marshall-61123-unsplash.jpg';
import Arrow from 'components/Arrow';

class TickerCard extends React.Component {
  static getLinkText() {
    // const { type } = this.props;
    // return type === 'article' ? 'Read the article' : 'Watch the video';
    return 'See the Feature';
  }

  render() {
    const {
      background,
      handleClick,
      logo,
      logoAlt,
      title,
      subtitle,
      timestamp,
      type,
      url,
      videoid,
    } = this.props;

    return (
      <div
        className="ticker--card"
        onClick={() => handleClick(type, url, videoid, timestamp)}
        onKeyPress={() => handleClick(type, url, videoid, timestamp)}
        role="button"
        style={{ backgroundImage: `url(${background || DefaultImage})` }}
        tabIndex={0}
      >
        <div className="ticker--card--overlay">
          <div className="ticker--card--container">
            <div className="ticker--card--logo">
              <img src={logo} alt={logoAlt} />
            </div>

            <div className="ticker--card--title">
              <p>{title}</p>
              <h3>{subtitle}</h3>
            </div>

            <div className="ticker--card--link">
              {TickerCard.getLinkText()}
              <Arrow className="pl2" color="#CFBDA1" />
            </div>
          </div>
        </div>

        {/* <div className="ticker--card--overlay">
          {this.getContent()}
        </div> */}
      </div>
    );
  }
}

TickerCard.propTypes = {
  background: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
  logo: PropTypes.string,
  logoAlt: PropTypes.string,
  subtitle: PropTypes.string.isRequired,
  timestamp: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  url: PropTypes.string,
  videoid: PropTypes.string,
};

TickerCard.defaultProps = {
  background: '',
  logo: '',
  logoAlt: '',
  timestamp: null,
  url: null,
  videoid: null,
};

export default TickerCard;
