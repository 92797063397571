import React from 'react';
import PropTypes from 'prop-types';
import Link from 'components/Link';
import Arrow from 'components/Arrow';
import ModalVideoPlayer from 'components/ModalVideoPlayer';
import SuccessSlider from './SuccessSlider';
import SuccessOutCommunicate from './SuccessOutCommunicate';

class Successes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      modalVideoId: null,
      modalTimestamp: null,
    };

    this.handleClick = this.handleClick.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  handleClick(type, url, videoId, timestamp) {
    const { modalIsOpen } = this.state;

    if (type === 'video' && !modalIsOpen) {
      // Open video player in modal
      this.openModal(videoId, timestamp);
    }

    if (type === 'article') {
      // Open article in a new window
      window.open(url, '_blank');
    }
  }

  closeModal() {
    this.setState(() => ({ modalIsOpen: false }));
  }

  openModal(videoId, timestamp) {
    const { modalIsOpen } = this.state;

    if (!modalIsOpen) {
      this.setState(() => ({
        modalIsOpen: true,
        modalVideoId: videoId,
        modalTimestamp: timestamp,
      }));
    }
  }

  render() {
    const {
      className,
      title,
      successesSummary,
      outcommunicateSummary,
    } = this.props;
    const { modalIsOpen, modalTimestamp, modalVideoId } = this.state;

    return (
      <section className={`success-list w-80 center mv6 ${className}`}>
        <ModalVideoPlayer
          isOpen={modalIsOpen}
          onClose={this.closeModal}
          timestamp={modalTimestamp}
          videoId={modalVideoId}
        />

        <h1>{title}</h1>
        <p>{successesSummary}</p>

        <SuccessSlider />

        <p>{outcommunicateSummary}</p>

        <SuccessOutCommunicate onCardClick={this.handleClick} />

        <div className="tc pt2">
          <Link to="/strategic-communications" className="no-underline">
            VIEW ADDITIONAL FEATURES
            <Arrow className="mh2" />
          </Link>
        </div>
      </section>
    );
  }
}

Successes.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  successesSummary: PropTypes.string.isRequired,
  outcommunicateSummary: PropTypes.string.isRequired,
};

Successes.defaultProps = {
  className: '',
};

export default Successes;
